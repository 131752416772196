<br /><br />
<div class="container">
  <br />
  <h1>{{ ('home.title-name-help' | translate) }}</h1>
  <div class="red-shadow"><hr /></div> 

 <div class="info-views interestinglinks">	
  <div class="content-info-views container" >      
      <p style="margin: 20px;"><small style="color: #9F281C; font-weight: bold; font-size: 20px;" >{{ ('title-ayudaCenter-name' | translate) }}</small> {{ ('title-ayudaCenter' | translate) }}. </p> 
      <hr>

      <!-- ** -->
      <div class="accordion" id="accordionExample" style="margin-bottom: 15px;">
          <div class="card">
            <div class="card-header" id="headingOne">
                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOnerecursos" aria-expanded="true" aria-controls="collapseOne">
                  <h3>{{ ('title-ayudaCenter-item1' | translate) }}</h3>
                </button>
            </div> 
            <div id="collapseOnerecursos" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
              <div class="card-body">
                  <p>
                      1.	{{ ('title-ayudaCenter-item1-description' | translate) }}. <br>
                      2.	{{ ('title-ayudaCenter-item1-description1' | translate) }}.
      
                  </p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTworecursos" aria-expanded="false" aria-controls="collapseTwo">
                  <h3>{{ ('title-ayudaCenter-item2' | translate) }} </h3>
                </button>
            </div>
            <div id="collapseTworecursos" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
              <div class="card-body">
                  <p>{{ ('title-ayudaCenter-item2-description' | translate) }}.</p>
              </div>
            </div>
          </div>
          <!-- **** -->
          <div class="card">
              <div class="card-header" id="headingTwo">
                  <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwoautor" aria-expanded="false" aria-controls="collapseTwo">
                      <h3>{{ ('title-ayudaCenter-item3' | translate) }}  </h3>
                  </button>
              </div>
              <div id="collapseTwoautor" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                <div class="card-body">
                  <p>{{ ('title-ayudaCenter-item3-description' | translate) }}<br>
                      1.	{{ ('title-ayudaCenter-item3-description1' | translate) }}. <br>
                      2.	{{ ('title-ayudaCenter-item3-description2' | translate) }}. <br>
                      3.	{{ ('title-ayudaCenter-item3-description3' | translate) }}. <br>
                      </p>
                </div>
              </div>
            </div>
            <!-- ** -->
            <div class="card">
              <div class="card-header" id="headingTwo">
                  <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwoFiltrar" aria-expanded="false" aria-controls="collapseTwo">
                      <h3>{{ ('title-ayudaCenter-item4' | translate) }}</h3>
                  </button>
              </div>
              <div id="collapseTwoFiltrar" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                <div class="card-body">
                  <p>{{ ('title-ayudaCenter-item4-description' | translate) }}. <br>
                      1.	{{ ('title-ayudaCenter-item4-description1' | translate) }}. <br>
                      2.	{{ ('title-ayudaCenter-item4-description2' | translate) }}. <br>
                      3.	{{ ('title-ayudaCenter-item4-description3' | translate) }}. <br>
                      4.	{{ ('title-ayudaCenter-item4-description4' | translate) }}
                      </p>
                </div>
              </div>
            </div>
            <!-- *** -->
            <div class="card">
              <div class="card-header" id="headingTwo">
                  <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwoTema" aria-expanded="false" aria-controls="collapseTwo">
                      <h3>{{ ('title-ayudaCenter-item5' | translate) }}</h3>
                  </button>
              </div>
              <div id="collapseTwoTema" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                <div class="card-body">
                  <p>{{ ('title-ayudaCenter-item5-description' | translate) }}. <br>
                      1.	{{ ('title-ayudaCenter-item5-description1' | translate) }}. <br>
                      2.	{{ ('title-ayudaCenter-item5-description2' | translate) }}. <br>
                      3.	{{ ('title-ayudaCenter-item5-description3' | translate) }} 
                      </p>
                </div>
              </div>
            </div>
            <!-- ** -->
            <div class="card">
              <div class="card-header" id="headingTwo">
                <h3 class="mb-0">
                  <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwoTitulo" aria-expanded="false" aria-controls="collapseTwo">
                      <h3>{{ ('title-ayudaCenter-item6' | translate) }}</h3>
                  </button>
                </h3>
              </div>
              <div id="collapseTwoTitulo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                <div class="card-body">
                  <p>{{ ('title-ayudaCenter-item6-description' | translate) }}. <br>
                      1.	{{ ('title-ayudaCenter-item6-description1' | translate) }}. <br>
                      2.	{{ ('title-ayudaCenter-item6-description2' | translate) }}. <br>
                      3.	{{ ('title-ayudaCenter-item6-description3' | translate) }}
                      </p>
                </div>
              </div>
            </div>
            <!-- ** -->
            <div class="card">
              <div class="card-header" id="headingTwo">
                  <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwocomunidades" aria-expanded="false" aria-controls="collapseTwo">
                      <h3>{{ ('title-ayudaCenter-item7' | translate) }}</h3>
                  </button>
              </div>
              <div id="collapseTwocomunidades" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                <div class="card-body">
                  <p>{{ ('title-ayudaCenter-item7-description' | translate) }}: <br>
                      1.	{{ ('title-ayudaCenter-item7-description1' | translate) }}. <br>
                      2.	{{ ('title-ayudaCenter-item7-description2' | translate) }}. <br>
                      3.	{{ ('title-ayudaCenter-item7-description3' | translate) }}. <br>
                      </p>
                </div>
              </div>
            </div>
            <!-- ** -->
            <div class="card">
              <div class="card-header" id="headingTwo">
                  <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwoRIDUGB" aria-expanded="false" aria-controls="collapseTwo">
                      <h3>{{ ('title-ayudaCenter-item8' | translate) }} </h3>
                  </button>
              </div>
              <div id="collapseTwoRIDUGB" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                <div class="card-body">
                  <p>{{ ('title-ayudaCenter-item8-description' | translate) }}. <br>
                      1.	{{ ('title-ayudaCenter-item8-description1' | translate) }}. <br>
                      2.	{{ ('title-ayudaCenter-item8-description2' | translate) }}. <br>
                      3.	{{ ('title-ayudaCenter-item8-description3' | translate) }}.  <br>                      
                      
                      </p>
                </div>
              </div>
            </div>
        </div>
  </div>
</div>
</div>