import { ChangeDetectionStrategy, Component, Injectable } from '@angular/core';
import { ItemComponent } from '../shared/item.component';
import { ViewMode } from '../../../../core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../shared/object-collection/shared/listable-object/listable-object.decorator';
import { Router } from '@angular/router';
import { RouteService } from '../../../../core/services/route.service';

/**
 * Component that represents a publication Item page
 */

@listableObjectComponent('Publication', ViewMode.StandalonePage)
@Component({
  selector: 'ds-publication',
  styleUrls: ['./publication.component.scss'],
  templateUrl: './publication.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@Injectable({
  providedIn: 'root'
})
export class PublicationComponent extends ItemComponent {
  element: string;

  constructor(public router: Router, protected routeService: RouteService) {
    super(routeService);
    const pieces = this.router.url.split(/[\s/]+/);
    const last = pieces[pieces.length - 1];
    this.element = last;
  }

}
