<a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/collections/' + object.id]" class="lead">
    {{object.name}}
</a>
<span *ngIf="linkType == linkTypes.None" class="lead">
    {{object.name}}
</span>
 <span class="pr-2">&nbsp;</span>
  <span *ngIf="object.archivedItemsCount >= 0" class="badge badge-pill badge-secondary align-self-center archived-items-lead">{{object.archivedItemsCount}}</span>

<div *ngIf="object.shortDescription" class="text-muted abstract-text">
    {{object.shortDescription}} 
</div>
