<div class="communities">
    <!-- img src="/assets/images/tesis.png" alt="Portada"-->
    <div id="arrow">
            <a class="lead" *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/communities/' + object.id]">
                <i class="fa fa-arrow-circle-right" aria-hidden="true"></i> {{object.name}} z
            </a> 
   </div>
    
</div>
<!--h4 class="list-group-item-heading">
    <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/communities/' + object.id]" class="lead">
    {{object.copyrightText}}
    </a>   
</h4-->

<!--div class="list-group">
        <div class="list-group-item row">    
            <div class="col-md-12">            
                <h4 class="list-group-item-heading">
                    <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/communities/' + object.id]" class="lead">
                    {{object.name}}
                    </a>   
                </h4>                
            </div>
        </div>      
</div-->    
