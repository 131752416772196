import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-filter-slider-home',
  templateUrl: './filter-slider-home.component.html',
  styleUrls: ['./filter-slider-home.component.scss']
})
export class FilterSliderHomeComponent implements OnInit {
  filters: any;

  // constructor() { }

  ngOnInit(): void {
    // cod ehere
  }
}
