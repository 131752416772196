import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-perfil-investigador',
  templateUrl: './perfil-investigador.component.html',
  styleUrls: ['./perfil-investigador.component.scss']
})
export class PerfilInvestigadorComponent implements OnInit {

  // constructor() { }

  ngOnInit(): void {
    // code here
  }

}
