<br /><br />
<div class="container">
  <br />
  <h1>{{ ('home.title-name-about' | translate) }}
    <span style="color:#9F281C; font-weight: bold;">RIDUGB</span>
  </h1>  
    
    <div class="red-shadow"><hr /></div> 
      <div style="display: flex;justify-content: center; align-items: center;" >
        <div>
            <!--p style="text-align: justify; font-size: 23px !important;">
              {{ ('title-aboutus' | translate) }}
            </p-->
            
          <p>
            {{ ('title-aboutus-item1' | translate) }}
          </p>

          <p>
            {{ ('title-aboutus-item2' | translate) }}:
          </p>
          <ul class="list-style">
            <li class="textLI">{{ ('title-aboutus-item3' | translate) }}.</li>
            <li class="textLI">{{ ('title-aboutus-item4' | translate) }}.</li>
            <li class="textLI">{{ ('title-aboutus-item5' | translate) }}.</li>
            <li class="textLI">{{ ('title-aboutus-item6' | translate) }}.</li>
            <li class="textLI">{{ ('title-aboutus-item7' | translate) }}.</li>
          </ul>
          <div class="wrapper">
            <br />
            <ul class="menu">
              <li class="menuLi"><a
                  [routerLink]="[ '/helpcenter' ]"> {{ ('title.subnumero-item5' | translate) }} </a></li>
              <li class="menuLi"><a
                  [routerLink]="[ '/repositorypolicies' ]"> {{ ('title.subnumero-item4' | translate) }}</a></li>
              <li class="menuLi"><a
                  [routerLink]="[ '/creativecommonslicense' ]">{{ ('title.subnumero-item6' | translate) }}</a></li>
            </ul>
          </div>
        </div>
    </div>
</div>

  