<div class="row">
    <div *ngIf="showThumbnails" class="offset-md-2"></div>
    <div [ngClass]="showThumbnails ? 'col-9' : 'col-md-12'">      
      <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer"  [routerLink]="['/communities/' + dso.id]" class="lead" [innerHTML]="dsoTitle"></a>
      <span *ngIf="linkType == linkTypes.None" class="lead" [innerHTML]="dsoTitle"></span>
      <ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>
      <div *ngIf="dso.shortDescription" class="text-muted abstract-text" [innerHTML]="firstMetadataValue('dc.description.abstract')"></div>
    </div>
</div>
<hr class="hr" />
