
<article class="container card">
    <section class="card-header row">

        <div class="col-12 col-md-4">
            <h2 class="text-black">
                Perfil del investigador Cristian Zayas 😁
                
            </h2>
        </div>
        <div class="col-12 col-md-8">
            <nav class="navbar navbar-expand-lg bg-light">
                <div class="container-fluid">
                  <a class="navbar-brand text-black" href="#"> <i class="fa fa-globe" aria-hidden="true"></i> Colaboraciones </a>
                  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                  </button>
                  <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul class="navbar-nav">
                      <li class="nav-item">
                        <a class="nav-link active text-black" aria-current="page" href="#"> 
                            <i class="fa fa-eye" aria-hidden="true"></i>
                             Ver estadísticas </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link text-black"  href="#"><i class="fa fa-bell" aria-hidden="true"></i>   Alertas por Email </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link text-black" href="#"><i class="fa fa-rss" aria-hidden="true"></i>  RSS </a>
                      </li>                  
                     
                    </ul>
                  </div>
                </div>
              </nav>           
        </div>
       
       
    </section>
    <section class="card-body row">
        <div class="col-12 col-md-6">
           <aside class="d-flex justify-content-center">
            <img class="imgPerfil" src="https://cdn.pixabay.com/photo/2022/11/08/06/26/woman-7577808_960_720.jpg" alt="Cristian Zayas" sizes="" srcset="">
           </aside>
            <table class="table">
                <thead>
                  <tr>
                    <th scope="col"> </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Firma en Digital.CSIC(*)</th>
                    <td>Cristian Zayas</td>
                  </tr>
                  <tr>
                    <th scope="row">Otras firmas</th>
                    <td>Moreno, M.A.
                        Moreno, MA</td>
                  </tr>
                  <tr>
                    <th scope="row">Centro o instituto</th>
                    <td colspan="2">Centro o Instituto</td>
                  </tr>
                  <tr>
                    <th scope="row">Departamento</th>
                    <td colspan="2"> Departamento de Pomologia - Grupo
                        de Mejora</td>
                  </tr>
                  <tr>
                  <th scope="row">Categoría Profesional</th>
                    <td colspan="2"> Investigador Cientifico CSIC</td>
                  </tr>
                </tbody>
              </table>

        </div>
        <div class="col-12 col-md-6">
            <h2>Especialización</h2>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit vero sapiente commodi veniam. Molestiae quos fugiat quas aperiam. Repudiandae, quisquam. Quam similique illum a soluta eos iste, sit officia dolorum!
                Eos veritatis amet nisi eligendi dolor, expedita fuga delectus quam mollitia ducimus fugit iure nihil ab sint minus inventore quia voluptas error quibusdam. Explicabo iusto esse aliquid impedit alias eligendi!
                Fuga ipsa delectus nostrum est molestiae voluptates? Corrupti reprehenderit accusantium aut dolore sequi sint, officiis ea magnam saepe inventore odio perferendis voluptatibus dolor temporibus rem. Provident cupiditate totam ad alias!
                Nostrum voluptatum facere eveniet, debitis facilis quidem ratione nisi minima laborum natus placeat, quisquam molestias tenetur? Nihil, non, reprehenderit repellendus, temporibus aliquid labore cum quis rerum aliquam asperiores odio neque.
                Iure quibusdam a, fugit repellendus incidunt doloribus et, molestiae voluptatibus est deserunt tenetur pariatur, hic maiores illum ipsam dicta earum consectetur. Autem, soluta quo dicta hic provident explicabo et ut.
               </p>
               <table class="table">
                <thead>
                  <tr>
                    <th scope="col"> </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Email 📧</th>
                    <td>josecristianzayas@gmail.com</td>
                  </tr>
                  <tr>
                    <th scope="row">ORCID</th>
                    <td>https:www.cristianzayas.com</td>
                  </tr>
                  <tr>
                    <th scope="row">Perfil en Google ☺️</th>
                    <td colspan="2">Cristian Zayas</td>
                  </tr>
                  <tr>
                    <th scope="row">Página</th>
                    <td colspan="2"> </td>
                  </tr>
                  
                </tbody>
              </table>
        </div>   
       
    </section>
 </article>
