<div ngbDropdown class="navbar-nav" *ngIf="moreThanOneLanguage" display="dynamic" placement="bottom-right">
  <ul class="navbar-nav m-auto">
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" id="dropdown04" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        &nbsp;&nbsp;<i class="fa fa-globe"></i>&nbsp;{{ currentLangLabel() }}&nbsp;<i class='fa fa-caret-down'></i>
      </a>
      <div class="dropdown-menu" [attr.aria-label]="'nav.language' |translate">
        <a href="javascript:void(0);" class="dropdown-item" tabindex="0" #langSelect *ngFor="let lang of translate.getLangs()"
        (keyup.enter)="useLang(lang)"
        (click)="useLang(lang)">
          {{ langLabel(lang) }}
        </a>
      </div>
    </li>
  </ul>
</div>
