<header>
    <nav class="main-menu">
        <ul class=" nav justify-content-center ">           
            <li class=" nav-item dropdown">
              <div class="btn-group justify-content-center" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                <button type="button" class="btn text-white" ngbDropdownToggle>Colecciones</button>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <button  ngbDropdownItem><a [routerLink]="[ '/communities/0a754198-3adc-4a51-ada6-c26ed151317f' ]"> Artículos </a></button>
                  <button ngbDropdownItem><a [routerLink]="[ '/communities/0286d7e4-f102-4942-898d-5294a5632484' ]"> Investigaciones </a> </button>
                  <button ngbDropdownItem> <a [routerLink]="[ '/communities/6f50243e-0be4-4dea-80ee-be08b9e9708d' ]"> Investigación de Cátedra </a> </button>
                  <button ngbDropdownItem> <a [routerLink]="[ '/communities/f7a079f0-3b3b-438a-8680-6c72ce144d02' ]"> Multimedia </a> </button>
                  <button ngbDropdownItem> <a [routerLink]="[ '/communities/c0287b35-f3a4-45bb-a755-a166ce06dc12' ]"> Pósters Académicos </a> </button>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown">
              <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                <button type="button" class="btn text-white" ngbDropdownToggle>Institucional</button>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <button ngbDropdownItem>Aula Virtual</button>
                  <button ngbDropdownItem>Bibilioteca</button>
                  <button ngbDropdownItem > <a href="https://www.ugb.edu.sv/">Sitio UGB</a> </button>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown">
              <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                <button type="button" class="btn text-white" ngbDropdownToggle>RIDUGB</button>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <button ngbDropdownItem>Acerca de RIDUGB</button>
                  <button ngbDropdownItem>Políticas del
                    Repositorio</button>
                  <button ngbDropdownItem>Lineamientos del
                    Repositorio</button>
                  <button ngbDropdownItem>Enlaces de
                    Interés</button>
                </div>
              </div>
            </li>
          
        </ul>
      </nav>
  </header>
  <img src="/assets/images/banner-help.png" width="1497.73" class="img-fluid">

  <!--Menu principal-->
  <nav class="main-menu">
    <ul class="nav justify-content-center help-item">
       <li class="nav-item">        
        <a class="nav-link active text-white" aria-current="page" href="#">Temas</a>
       </li>

       <li class="nav-item">
        
        <a class="nav-link active text-white" aria-current="page" href="#">Informar Problema</a>

       </li>

       <li class="nav-item">
        
        <a class="nav-link active text-white" aria-current="page" href="#">Preguntas Frecuntes</a>

       </li>


     
    </ul>
  </nav>

<div class="column-2">
    <section class="section section-a">
        <div class="links">
            <br><br><br><br>
            <div>
                <div >
                    <a href="#" target="_blank">
                        <div class="link">Asignación de Roles</div>
                    </a>
                    <a href="#" target="_blank">
                        <div class="link">Búsqueda de recursos</div>
                    </a>
                    <a href="#" target="_blank">
                        <div class="link">Filtrar por autor</div>
                    </a>
                    <a href="#" target="_blank">
                        <div class="link">Filtrar por año</div>
                    </a>
                    <a href="#" target="_blank">
                        <div class="link">Filtrar por tema</div>
                    </a>
                    
                
                </div>
                
            </div>
            
        </div>
    </section>
    
    
    <section class="section">
        <div class="links ">
            <br><br><br><br>
            <div>
                <div>
                    
                    <a href="#" target="_blank">
                        <div class="link">Filtrar por titulo</div>
                    </a>
                    <a href="#" target="_blank">
                        <div class="link">Creación de Colecciones</div>
                    </a>
                    <a href="#" target="_blank">
                        <div class="link">Creación de Comunidades</div>
                    </a>
                    <a href="#" target="_blank">
                        <div class="link">Creación de Subcomunidades</div>
                    </a>
                    <a href="#" target="_blank">
                        <div class="link">Creación Perfil del Investigador</div>
                        
                    </a>
                </div>
            </div>
            
        </div>
    </section>
    
    <section class="section">
        <div class="links ">
            <br><br><br><br>
            <div>
                <div>
                    <a href="#" target="_blank">
                        <div class="link">Perfil del Investigador</div>
                        
                    </a>
                    <a href="#" target="_blank">
                        <div class="link">Publicar Recursos</div>
                    </a>
                    <a href="#" target="_blank">
                        <div class="link">Registrar Recursos</div>
                    </a>
                    <a href="#" target="_blank">
                        <div class="link">Cambiar Contraseña</div>
                    </a>
                    <a href="#" target="_blank">
                        <div class="link">¿Olvido su Contraseña?</div>
                    </a>
                
                </div>
            </div>
            
        </div>
    </section>
    
    <br><br>
  
   </div>
  
  

