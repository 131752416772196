import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss']
})
export class HelpCenterComponent implements OnInit {

  // constructor() { }

  ngOnInit(): void {
    // code here
  }

}
