import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-creative-commons-license',
  templateUrl: './creative-commons-license.component.html',
  styleUrls: ['./creative-commons-license.component.scss']
})
export class CreativeCommonsLicenseComponent implements OnInit {

  // constructor() { }

  ngOnInit(): void {
    // content here
  }

}
