<br /><br />
<div class="container">
  <br />
  <h1>Política de Contenidos y Depósito</h1>
  <div class="red-shadow"><hr /></div> 


 <div class="info-views interestinglinks">	
    <div class="content-info-views container" >        
        <p> 
          El RIDUGB ha sido creado para visibilizar la producción científica y académica de la institución, facilitando el acceso a la información desde cualquier lugar del mundo, garantizando con ello la preservación de las publicaciones. Pero, además de permite la socialización y divulgación de materiales que sean creados por autores o instituciones externas a la UGB y que, por su valor académico y/o científico su publicación se considere viable. Para delimitar los contenidos que se depositarán en el RIDUGB se establecen los elementos siguientes. 
        </p> 
        <hr>
  
        <!-- ** -->
        <div class="accordion" id="accordionExample" style="margin-bottom: 15px;">
            <div class="card">
              <div class="card-header" id="headingOne">
                  <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOnerecursos" aria-expanded="true" aria-controls="collapseOne">
                    <h3>Tipos de Materiales Admitidos en el RIDUGB </h3>
                  </button>
              </div> 
              <div id="collapseOnerecursos" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div class="card-body">
                    <p>Los tipos de documentos a depositar en el repositorio son: artículos académicos, informes de investigación, pósteres académicos, materiales audiovisuales, tesis, investigaciones de cátedra, revistas institucionales e informes de proyección social; las obras depositadas deberán estar en idioma español o inglés.</p> 
                    <p>El repositorio acepta archivos con formado PDF, JPEG, MPEG, MP4, MP3, RTF, TIFF, cualquier otro formato, siempre y cuando se aclare el programa con el que se abre el archivo para realizar su oportuna revisión. </p>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingOne">
                  <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOnerecursos1" aria-expanded="true" aria-controls="collapseOne">
                    <h3>Depósito de Materiales en el RIDUGB </h3>
                  </button>
              </div> 
              <div id="collapseOnerecursos1" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div class="card-body">
                    <p>Los materiales que se incorporan en el RIDUGB deberán seguir el siguiente proceso: </p> 
                    <ul class="textLI">
                      <li>
                        Para incorporar materiales no producidos en la Vicerrectoría de Investigación y Proyección Social, se deberá realizar una solicitud formal dirigida hacia Editorial, en la cual se explique el tipo de material y se presente lo siguiente: 
                        <ul style="margin-left: 30px;">
                          <li>Un resumen entre 100 - 200 palabras del contenido a publicar en el repositorio.  </li>
                          <li>Presentar en una página un resumen del currículum del autor y en caso de ser varios, presentar el currículum del autor principal e incluir de forma breve los datos del equipo en la misma hoja. </li>
                        </ul>
                      </li>
                      <li>
                        Podrán publicarse resúmenes técnicos de documentos editados y publicados en revistas indexadas académicas o científicas a nivel nacional o internacional, que ya hayan estado sometidos a procesos de revisión por pares. Está publicación se hará bajo solicitud de los autores o bajo solicitud de la institución que ha producido el material. 
                      </li>
                      <li>
                        Se publicarán todos aquellos materiales que tienen como autores a investigadores, docentes investigadores, estudiantes, cooperantes y miembros de la comunidad Universitaria UGB, siempre y cuando cumpla con los estándares de calidad institucionales. 
                      </li>
                      <li>
                        Todos los materiales, independiente de su origen estarán sujetos al proceso de revisión de contenido establecido por Vicerrectoría de Investigación y Proyección Social y al proceso de revisión Editorial. 
                      </li>
                      <li>
                        Se consideran aquellos materiales no contemplados en esta política que puedan generar valor para la comunidad científica y académica de El Salvador. 
                      </li>
                      <li>
                        La UGB se reserva los derechos de publicación y/o remoción de materiales del RIDUGB. 
                      </li>
                      <li>
                        Se publicarán materiales con licencias de libre uso (Creative Commons), pero, en el caso de que los materiales tengan Copy Rights, se deberán ceder los derechos de promoción y divulgación a la UGB.  
                      </li>
                    </ul>
                    <p>
                      No se incluyen en esta política aquellas investigaciones internas de carácter confidencial sea por sus características, abordaje o acuerdos previos con terceros, así como aquellas que tendrán un Registro de Propiedad Intelectual.  
                    </p>
                </div>
              </div>
            </div>
          </div>
    </div>
  </div>
</div>