  <div class="card" [@focusShadow]="(isCollapsed$ | async)?'blur':'focus'">
    <ds-truncatable [id]="dso.id">
      <div class="position-absolute ml-1">
        <ng-content></ng-content>
        
      </div>
      <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="[itemPageRoute]"
         class="card-img-top full-width">
          <div>
              <ds-thumbnail [thumbnail]="dso?.thumbnail | async" [limitWidth]="false">
              </ds-thumbnail>
          </div>
      </a>
      <span *ngIf="linkType == linkTypes.None" class="card-img-top full-width">
          <div>
              <ds-thumbnail [thumbnail]="dso?.thumbnail | async" [limitWidth]="false">
              </ds-thumbnail>
          </div>
      </span>
      <div class="card-body">
          <ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>
          <ds-truncatable-part [id]="dso.id" [minLines]="3" type="h4">
              <h4 class="card-title" [innerHTML]="firstMetadataValue('dc.title')"></h4>
          </ds-truncatable-part>
          <p *ngIf="dso.hasMetadata(['dc.contributor.author', 'dc.creator', 'dc.contributor.*'])"
             class="item-authors card-text text-muted">
             
              <ds-truncatable-part [id]="dso.id" [minLines]="1">
                  <span *ngIf="dso.hasMetadata('dc.date.issued')" class="item-date">{{firstMetadataValue('dc.date.issued')}}</span>
                  <span *ngFor="let author of allMetadataValues(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']);">,
                      <span [innerHTML]="author"></span>
                  </span>
              </ds-truncatable-part>
          </p>
          <p *ngIf="dso.hasMetadata('dc.description.abstract')" class="item-abstract card-text">
              <ds-truncatable-part [id]="dso.id" [minLines]="3">
                  <span [innerHTML]="firstMetadataValue('dc.description.abstract')"></span>
              </ds-truncatable-part>
          </p>
          <div *ngIf="linkType != linkTypes.None" class="text-center">
              <a [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="[itemPageRoute]"
                 class="lead btn btn-primary viewButton">{{ "mydspace.view-btn" | translate }}</a>
          </div>
      </div>
    </ds-truncatable>
    <ng-content></ng-content>
  </div>
