import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  // constructor() { }

  ngOnInit(): void {
    // code here
  }

}
