import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-repository-policies',
  templateUrl: './repository-policies.component.html',
  styleUrls: ['./repository-policies.component.scss']
})
export class RepositoryPoliciesComponent implements OnInit {

  // constructor() { }

  ngOnInit(): void {
    // code here
  }

}
