<ng-container *ngVar="(bitstreams$ | async) as bitstreams" >
  <ds-metadata-field-wrapper *ngIf="bitstreams?.length > 0" [label]="label | translate">
    <div class="file-section">


      <ng-container *ngFor="let file of bitstreams; let last=last;">
        <button class="btn badge" (click)="open(mymodal)">
          <span class="fas fa-eye"></span>           
        </button>    
        <a [href]="downloadUrl" target="_blank"><span class="btn badge" style="margin-left: 3px"><i class='fa fa-download'></i></span></a>
        <span class="dont-break-out">{{file?.name}}</span>
        <span class="dont-break-out">({{(file?.sizeBytes) | dsFileSize }})</span>
        <span class="dont-break-out" *ngIf="!last" innerHTML="{{separator}}"></span>

        <ng-template #mymodal let-modal>
          <div class="modal-header">
            <h2>{{ item?.name }}</h2>            
            <a [href]="downloadUrl" target="_blank"><span class="badge badge-stat" style="margin-left: 5px"><i class='fa fa-download'></i></span></a>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <iframe *ngIf="getExtension(file?.name) == 'pdf'" [src]="iframeSrc" style="width:100%;height:70vh"></iframe>
            <img *ngIf="getExtension(file?.name) == 'jpg'" src={{imgSrc}} alt="" class="center"/>
          </div>
        </ng-template>
      </ng-container>      

      <!--ds-file-download-link *ngFor="let file of bitstreams; let last=last;" [bitstream]="file" [item]="item">
        <span hidden style="background:moccasin">{{file?.name}}</span>
        <span hidden style="background:moccasin">({{(file?.sizeBytes) | dsFileSize }})</span>
        <span *ngIf="!last" innerHTML="{{separator}}"></span>
      </ds-file-download-link-->
      <!--ds-loading *ngIf="isLoading" message="{{'loading.default' | translate}}" [showMessage]="false"></ds-loading-->
      <div *ngIf="!isLastPage" class="mt-1" id="view-more">
        <a class="bitstream-view-more btn btn-outline-secondary btn-sm" [routerLink]="" (click)="getNextPage()">{{'item.page.bitstreams.view-more' | translate}}</a>
      </div>
      <div *ngIf="isLastPage && currentPage != 1" class="mt-1" id="collapse">
        <a class="bitstream-collapse btn btn-outline-secondary btn-sm" [routerLink]="" (click)="currentPage = undefined; getNextPage();">{{'item.page.bitstreams.collapse' | translate}}</a>
      </div>
    </div>
  </ds-metadata-field-wrapper>
</ng-container>



